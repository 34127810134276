import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ title }) {
  const { site, contentfulAbout } = useStaticQuery(
    graphql`
      {
        contentfulAbout {
          photos {
            file {
              url
            }
          }
        }
        site {
          siteMetadata {
            title
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription =
    "Boys and Girls PG in Gurgaon, Sector 57. Fully furnished, newly built, neat and clean, partial power backup, secured, professionally managed, single, double and triple sharing, Starting from ₹5500, Inaugral discount available, near mr digital park";

  const imgurl = contentfulAbout.photos[0].file.url;

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title} | Boys and Girls PG in Gurgoan, Sector 57`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: `${site.siteMetadata.title} | Boys and Girls PG in Gurgoan, Sector 57`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: imgurl,
        },
        {
          name: `twitter:card`,
          content: imgurl,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: `${site.siteMetadata.title} | Boys and girls PG in Gurgaon, Sector 57`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:url`,
          content: site.siteUrl,
        },
        {
          name: `keywords`,
          content: `pg, paying guest, gurgaon, room for rent, sharing apartment, sector 57 gurgaon, cloud residency, 3 room, rooms in gurgaon, near sector 61, pg near sector 61`,
        },
      ]}
    />
  );
}

export default SEO;
