import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import scrollTo from "gatsby-plugin-smoothscroll";

import SEO from "../components/seo";

import "../styles/home.css";

export default () => {
  const data = useStaticQuery(
    graphql`
      query HomeQuery {
        contentfulAbout {
          name
          location {
            lat
            lon
          }
          address
          email
          phoneNumber
          phoneNumber2
          phoneNumber3
          descriptionText {
            childMarkdownRemark {
              html
            }
          }
          photos {
            hd: fixed(width: 1920) {
              src
            }
          }
          description2 {
            childMarkdownRemark {
              html
            }
          }
        }
        allContentfulAmenities {
          edges {
            node {
              name
              vector {
                file {
                  url
                }
              }
              contents
            }
          }
        }
        allContentfulNearby {
          edges {
            node {
              name
              drivingTime
              distanceInMeters
            }
          }
        }
        allContentfulRoomTypes {
          edges {
            node {
              name
              pricePerMonth
              originalPrice
              photo {
                file {
                  url
                }
                fluid(maxWidth: 1024) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    `
  );

  const [formData, setFormData] = useState({
    email: "",
    number: "",
    name: "",
    message: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [menuHeight, setMenuHeight] = useState("0%");

  return (
    <>
      <SEO title="Home" />
      <div
        className="overlay text-white font-medium text-2xl z-50"
        style={{ height: menuHeight }}
      >
        <span className="closebtn" onClick={() => setMenuHeight("0%")}>
          &times;
        </span>
        <div className="overlay-content cursor-default z-10">
          <span
            className="cursor-pointer"
            onClick={() => {
              scrollTo("#home");
              setMenuHeight("0%");
            }}
          >
            Home
          </span>
          <br />
          <br />
          <span
            className="cursor-pointer"
            onClick={() => {
              scrollTo("#amenities");
              setMenuHeight("0%");
            }}
          >
            Amenities
          </span>
          <br />
          <br />
          <span
            className="cursor-pointer"
            onClick={() => {
              scrollTo("#nearby");
              setMenuHeight("0%");
            }}
          >
            Nearby Places
          </span>
          <br />
          <br />
          <span
            className="cursor-pointer"
            onClick={() => {
              scrollTo("#rooms");
              setMenuHeight("0%");
            }}
          >
            Available Rooms
          </span>
          <br />
          <br />
          <span
            className="cursor-pointer"
            onClick={() => {
              scrollTo("#location");
              setMenuHeight("0%");
            }}
          >
            Location
          </span>
          <br />
          <br />
          <span
            className="cursor-pointer"
            onClick={() => {
              scrollTo("#about");
              setMenuHeight("0%");
            }}
          >
            About us
          </span>
          <br />
          <br />
          <span
            className="cursor-pointer"
            onClick={() => {
              scrollTo("#contact");
              setMenuHeight("0%");
            }}
          >
            Contact us
          </span>
        </div>
      </div>
      <header
        className="fixed text-white flex top-0 w-screen py-4 xl-px-24 lg:px-16 px-8 cursor-default z-10"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
      >
        <span className="font-bold" onClick={() => scrollTo("#home")}>
          PG for Boys and Girls
        </span>
        <div className="hidden lg:flex ml-auto items-header">
          <span className="mr-4" onClick={() => scrollTo("#amenities")}>
            Amenities
          </span>
          <span className="mr-4" onClick={() => scrollTo("#nearby")}>
            Nearby Places
          </span>
          <span className="mr-4" onClick={() => scrollTo("#rooms")}>
            Available Rooms
          </span>
          <span className="mr-4" onClick={() => scrollTo("#location")}>
            Location
          </span>
          <span className="mr-4" onClick={() => scrollTo("#about")}>
            About us
          </span>
          <span onClick={() => scrollTo("#contact")}>Contact us</span>
        </div>
        <div
          className="flex lg:hidden ml-auto"
          onClick={() => setMenuHeight("100%")}
        >
          <svg viewBox="0 0 100 80" width="40" height="20" fill="white">
            <rect width="100" height="15"></rect>
            <rect y="30" x="30" width="70" height="15"></rect>
            <rect y="60" width="100" height="15"></rect>
          </svg>
        </div>
      </header>
      <div>
        <div
          id="home"
          className="w-screen h-screen bg-cover"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0 , 0, 0.45), rgba(0 ,0 ,0 , 0.45)) ,url(${data.contentfulAbout.photos[0].hd.src})`,
            backgroundPosition: "center",
          }}
        >
          <div className="w-full xl:w-2/3 h-full flex flex-col justify-center text-white lg:px-24 px-8">
            <h1 className="font-black text-4xl lg:text-6xl">
              {data.contentfulAbout.name}
            </h1>
            <h3 className="font-bold text-2xl lg:text-3xl">
              PG for Boys and Girls
            </h3>
            <div
              className="font-medium text-lg lg:text-xl pt-4"
              dangerouslySetInnerHTML={{
                __html:
                  data.contentfulAbout.descriptionText.childMarkdownRemark.html,
              }}
            />
          </div>
        </div>
        <div
          className="flex flex-col text-gray-900 px-8 lg:px-24 pt-12"
          id="amenities"
        >
          <h2 className="font-bold text-3xl lg:text-4xl text-border-bottom">
            Amenities
          </h2>
          <div
            className="grid justify-center"
            style={{
              gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))",
            }}
          >
            {data.allContentfulAmenities.edges.map(({ node }, i) => (
              <div
                className="flex flex-col items-center lg:mt-12 p-8 m-8"
                key={`am__${i}`}
              >
                <img
                  alt={node.name}
                  src={node.vector.file.url}
                  className="w-24 pb-4"
                  style={{
                    filter:
                      "invert(79%) sepia(35%) saturate(6572%) hue-rotate(359deg) brightness(103%) contrast(105%)",
                  }}
                />
                <h4 className="text-lg font-bold">{node.name}</h4>
                <ul className="w-full text-center">
                  {node.contents.map((content, j) => (
                    <li key={`c__${i}__$${j}`}>{content}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div
          className="flex flex-col text-gray-900 px-8 lg:px-24 py-12 bg-gray-200"
          id="nearby"
        >
          <h2 className="font-bold text-3xl lg:text-4xl text-border-bottom">
            Nearby Places
          </h2>
          <ul className="mt-8 list px-4">
            {data.allContentfulNearby.edges.map(({ node }, i) => (
              <li key={`near__${i}`} className="text-lg">
                <span className="font-bold">{node.name}</span> -{" "}
                <span>
                  {node.drivingTime
                    ? `${node.drivingTime} mins drive`
                    : `${node.distanceInMeters}m`}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div
          className="flex flex-col text-gray-900 px-4 lg:px-24 py-12 overflow-hidden"
          id="rooms"
        >
          <h2 className="font-bold text-3xl lg:text-4xl text-border-bottom">
            Available Rooms
          </h2>
          <div
            className="grid sm:gap-4 gap-8 py-12 lg:pt-0"
            style={{
              gridTemplateColumns: "repeat(auto-fit, minmax(364px, 1fr))",
            }}
          >
            {data.allContentfulRoomTypes.edges
              .sort(
                (a, b) =>
                  parseFloat(b.node.pricePerMonth) -
                  parseFloat(a.node.pricePerMonth)
              )
              .map(({ node }, i) => (
                <div
                  className="flex flex-col justify-center lg:mt-12 shadow-xl hover:shadow-2xl transition duration-500 rounded border border-gray-100 pb-4"
                  key={`am__${i}`}
                >
                  <Img
                    fluid={node.photo.fluid}
                    objectFit="cover"
                    alt=""
                    fadeIn={true}
                    className="h-64 w-full"
                  />
                  <div className="px-4 pt-4">
                    <div className="flex flex-col">
                      <h4 className="text-lg font-bold">{node.name}</h4>
                      <h4 className="text-lg">
                        <span className="line-through">
                          ₹{node.originalPrice}
                        </span>
                        &nbsp;<span>₹{node.pricePerMonth}</span>
                      </h4>
                      <h4 className="text-sm">
                        * Breakfast, lunch and dinner complimentary
                      </h4>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <h2 className="font-semibold text-xl lg:text-2xl">Meals</h2>
          <ul className="list pl-5">
            <li>
              <div className="flex">
                <span className="font-medium">Breakfast&nbsp;</span>
                <span>- 8AM to 10:00AM</span>
              </div>
            </li>
            <li>
              <div className="flex">
                <span className="font-medium">
                  Lunch&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span>- 1PM to 3:00PM</span>
              </div>
            </li>
            <li>
              <div className="flex">
                <span className="font-medium">
                  Dinner&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span>- 8PM to 10:00PM</span>
              </div>
            </li>
          </ul>
        </div>
        <div
          className="flex flex-col text-gray-900 px-8 lg:px-24 py-12 bg-gray-200"
          id="location"
        >
          <h2 className="font-bold text-3xl lg:text-4xl text-border-bottom">
            Location
          </h2>
          <h4 className="font-bold text-xl mt-12">
            F-188, Sector 57, Shushant Lok - II, Gurugram, Haryana 122011 (Near
            Emaar Digital Greens)
          </h4>
          <iframe
            title="map"
            className="w-full mt-4"
            height="500"
            src="https://maps.google.com/maps?q=cloud%20residency%20gurugram&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            loading="lazy"
          />
        </div>
        <div
          className="flex flex-col text-gray-900 px-8 lg:px-24 py-12"
          id="about"
        >
          <h2 className="font-bold text-3xl lg:text-4xl text-border-bottom">
            About us
          </h2>
          <div
            className="mt-8"
            dangerouslySetInnerHTML={{
              __html:
                data.contentfulAbout.description2.childMarkdownRemark.html,
            }}
          />
          <br />
          <div className="flex text-lg">
            <span className="material-icons">call</span>
            &nbsp;&nbsp;
            <a href={`tel:${data.contentfulAbout.phoneNumber}`}>
              {data.contentfulAbout.phoneNumber}&nbsp;
            </a>
          </div>
        </div>
        <div
          className="flex flex-col text-gray-900 px-8 lg:px-24 py-12 bg-gray-200"
          id="contact"
        >
          <h2 className="font-bold text-3xl lg:text-4xl text-border-bottom">
            Contact us
          </h2>
          <div className="flex flex-col">
            <div className="my-8 xl:pr-4">
              <h3 className="font-bold text-xl">
                {formSubmitted
                  ? "We will get back to you ASAP!"
                  : "How can we help?"}
              </h3>
              {!formSubmitted && (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    fetch("https://formspree.io/xaybgkkg", {
                      method: "POST",
                      body: JSON.stringify(formData),
                    });

                    setFormSubmitted(true);
                  }}
                >
                  <input
                    className="rounded shadow-md appearance-none border border-gray-100 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-xl hover:shadow-xl transition duration-500 mt-4"
                    type="text"
                    placeholder="Name"
                    required
                    value={formData.name}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        name: e.target.value,
                      });
                    }}
                  />
                  <input
                    className="rounded shadow-md appearance-none border border-gray-100 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-xl hover:shadow-xl transition duration-500 mt-4"
                    type="email"
                    placeholder="Email"
                    required
                    value={formData.email}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        email: e.target.value,
                      });
                    }}
                  />
                  <input
                    className="rounded shadow-md appearance-none border border-gray-100 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-xl hover:shadow-xl transition duration-500 mt-4"
                    type="text"
                    placeholder="Phone Number"
                    value={formData.number}
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        number: e.target.value,
                      });
                    }}
                  />
                  <textarea
                    className="rounded shadow-md appearance-none border border-gray-100 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-xl hover:shadow-xl transition duration-500 mt-4"
                    placeholder="Message"
                    rows="6"
                    required
                    value={formData.message}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        message: e.target.value,
                      });
                    }}
                  />
                  <button
                    type="submit"
                    className="px-4 py-2 mt-4 rounded shadow-md hover:shadow-xl transition duration-500"
                    style={{ backgroundColor: "orange" }}
                  >
                    Submit
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
